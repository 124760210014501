* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #e52165;
  --secondary-color: #0d1137;
  --text-color: #fff;
  --blob-size: 450px;
}

::selection {
  background-color: var(--primary-color);
}

body {
  background-color: var(--secondary-color);
  width: 100vw;
  height: 100vh;
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.page--background {
  z-index: -10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.page--background svg {
  color: var(--primary-color);
  width: var(--blob-size);
  height: var(--blob-size);
  transform-origin: 50%;
  animation: 10s ease-in-out infinite animate-blob;
}

.page--content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.page--header {
  font-size: 3rem;
  font-weight: 400;
}

.page--title {
  font-size: 2.25rem;
}

.page--subtitle {
  margin: .5rem 0;
  font-size: 1.25rem;
  font-weight: 400;
}

.page--contact {
  color: var(--text-color);
  margin-top: 3rem;
  font-size: 1.25rem;
}

@media only screen and (width <= 576px) {
  .page--background svg {
    --blob-size: 300px;
  }

  .page--header {
    font-size: 2.5rem;
  }

  .page--title {
    font-size: 1.75rem;
  }

  .page--contact {
    margin-top: 2rem;
  }
}

@keyframes animate-blob {
  0% {
    transform: scale(1)translate(10px, -30px);
  }

  38% {
    transform: scale(.8, 1)translate(80vw, 30vh)rotate(160deg);
  }

  40% {
    transform: scale(.8, 1)translate(80vw, 30vh)rotate(160deg);
  }

  78% {
    transform: scale(1.3)translate(0, 50vh)rotate(-20deg);
  }

  80% {
    transform: scale(1.3)translate(0, 50vh)rotate(-20deg);
  }

  100% {
    transform: scale(1)translate(10px, -30px);
  }
}
/*# sourceMappingURL=repo.bf26aa1a.css.map */
